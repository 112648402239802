import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'
import { Button, DialogActions, Paper, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system'
import { useAuth } from '../context/auth-context'

function Login() {
  const [account, setAccount] = useState({
    username: '',
    password: '',
  });

  const [errors, setErrors] = useState({});
  const [error, setError] = useState('');
  const {user, login, logout} = useAuth();
  let navigate = useNavigate();

  function handleChange(e) {
    setAccount({ ...account, [e.target.name]: e.target.value })
  }

  // async function handleCancel() {
  //   await logout()
  //   //reauthenticate()
  // }

  function doLogin() {
    setError('')
    setErrors({})
    login(account).then((r) => {
      //console.log('login res: ', r)
      if (!r || r.error) {
        setError(r?.error ?? { data: 'operation_failed' })
      } else {
        navigate('/', { replace: true })
      }
    })
  }

  return (
    <div className="center-screen">
      <Paper elevation={3} sx={{ maxWidth: 345 }}>
        {user && user.type && (
          <Button onClick={logout} size="large">
            {('login.logout')}
          </Button>
        )}
        {(!user || !user.type) && (
          <Box
            sx={{
              p: 2,
              '& .MuiTextField-root': { mt: 2, width: '100%' },
            }}
          >
            <Typography variant="h6" align="center">
              {('login.login')}
            </Typography>
            <TextField
              name="username"
              value={account.username}
              error={errors.username ? true : false}
              onChange={handleChange}
              label={('login.username')}
              helperText={errors.username ? errors.username : null}
            />
            <TextField
              name="password"
              hinttext={('login.password')}
              floatinglabeltext="Password"
              type="password"
              value={account.password}
              error={errors.password ? true : false}
              onChange={handleChange}
              label={('login.password')}
              helperText={errors.password ? errors.password : null}
              onKeyDown={(e) => {
                if (e.key === 'Enter') doLogin()
              }}
            />
            {error && (
              <Typography variant="caption" align="center" color="red">
                {error}
              </Typography>
            )}
            <DialogActions>
              <Button onClick={doLogin} size="large">
                {('login.login')}
              </Button>
            </DialogActions>
          </Box>
        )}
      </Paper>
    </div>
  )
}

export { Login }
