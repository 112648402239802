import {client} from './api-client';
import {useQuery} from '@tanstack/react-query';


function useSensorData(uuid) {
  const r = useQuery(['useSensorData', uuid], () => {
    return client(`sensor/${uuid}`).then(d => d.res);
  });
  return r;
}

function useSensorProperties(uuid) {
  const r = useQuery(['useSensorProperties', uuid], () => {
    return client(`sensor/${uuid}/properties`).then(d => d.res);
  });
  return r;
}

export {
  useSensorData, useSensorProperties
}
