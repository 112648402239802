//import {queryCache} from '@tanstack/react-query'
import {QueryCache} from 'react-query'
import * as auth from './auth'
//const apiURL = process.env.REACT_APP_API_URL
//const geocodingURL = process.env.REACT_APP_GEOCODING_URL

const apiURL = process.env.REACT_APP_API_URL;

const queryCache = new QueryCache({
  onError: (error) => {
    console.log(error)
  },
  onSuccess: (data) => {
    console.log(data)
  },
  onSettled: (data, error) => {
    console.log(data, error)
  },
})

async function client(endpoint, {data, token, headers: customHeaders, ...customConfig} = {}) {

  console.log("client token : ", token);

  const config = {
    method: data ? 'POST' : 'GET',
    body: data ? JSON.stringify(data) : undefined,
    headers: {
      Authorization: token ? `Bearer ${token}` : undefined,
//      'Content-Type': data ? 'application/json' : undefined,
      ...customHeaders,
    },
    ...customConfig,
  }

  if (data) {
    config.headers['Content-Type'] = data ? 'application/json' : undefined;
  }
  //---------------------------------------------
  //console.log("Fetch : ", `${apiURL}/${endpoint}`, config);
  return window.fetch(`${apiURL}/${endpoint}`, config)
    .then(async response => {
      // console.log("response.ok : ", response.ok);
      // if (!response.ok) {
      //   //throw new Error(`HTTP error! Status: ${response.status}`);
      // }


      console.log("*** response.status : ", response.status);
      if (response.status === 401) {
        queryCache.clear();
        await auth.logout();
        // refresh the page for them
        window.location.assign(window.location);
        return Promise.reject({message: 'Please re-authenticate.'});
      }

      const data = await response.json();
      if (response.ok) {
        return data;
      } else {
        return Promise.reject(data);
      }
    })
    // .catch((reason) => {
    //   console.log("reason : ", reason);
    //   throw new Error(reason);
    //   // if (reason.cause) {
    //   //   console.log("Had previously handled error");
    //   // } else {
    //   //   console.log(`Trouble with promiseGetWord(): ${reason}`);
    //   // }
    // })
}

export {client}
