import * as React from 'react';
import {useNavigate} from 'react-router-dom';

import Box from '@mui/material/Box';
//import Grid from '@mui/material/Unstable_Grid2';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
//import Skeleton from '@mui/material/Skeleton';
//import CircularProgress from '@mui/material/CircularProgress';

import {useTagDashboard} from '../utils/tag';

//{ "SALT" : "320d", "TYPE" : "ff", "LENGTH" : 21,
//"UPTIME_SEC" : 8742049, "TEMPERATURE" : -128, "IS_ENCRIPTED" : false,
//"TRIGGER_FLAG" : 0, "COMPANY_IDENT" : "4f09", "ACCELERATION_X" : 0,
//"ACCELERATION_Y" : 0.00390625, "ACCELERATION_Z" : 255.98438,
//"BATTERY_PERCENTAGE" : 94, "PAYLOAD_STRUCTURE_VER" : "01" }

function normalizeTim(o) {
  var t = "";

  if (!o) return "";

  if (o.days)
    t += o.days+"d ";

  if (o.hours)
    t += o.hours+"h ";

  if (o.minutes)
    t += o.minutes+"m ";

  if (o.seconds)
    t += o.seconds+"s ";

  if (t === "") t = "Just now"

  return t;
}

function ItemBoxTgBt5out({item}) {
  const navigate = useNavigate();

  var handleClick = () => {
    console.log("handleClick");
    navigate(`/sensor/${item.uuid}`, { replace: false });
  }

  return (
    <Card variant="outlined" sx={{height: "100%"}}>
      <CardContent>
        <Typography sx={{fontSize: 14}} color="text.secondary" gutterBottom>
          Name : {item.name ? item.name : item.ident}
        </Typography>
        <Typography sx={{mb: 1.5}} color="text.secondary">
          Place : {item.gw_name ? item.gw_name : item.gw_ident}
        </Typography>
        <Typography sx={{mb: 1.5}} color="text.secondary">
          {
            item.lasttagdata && (
              item.lasttagdata["TEMPERATURE"]===-128
                ? `Temp : Not available`
                : `Temp : ${item.lasttagdata["TEMPERATURE"].toFixed(2)}°`
              )
          }
        </Typography>
        <Typography sx={{mb: 1.5}} color="text.secondary">
          Battery : {
            item.lasttagdata && (
              item.lasttagdata["BATTERY_PERCENTAGE"]+'%'
            )
          }
        </Typography>
        <Typography sx={{mb: 1.5}} color="text.secondary">
          Last activity : {
            item.lasttagdata && (
              normalizeTim(item.last_activity)
            )
          }
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small" onClick={handleClick}>More</Button>
      </CardActions>
    </Card>
  )
}

function ItemBoxTgBt5in({item}) {
  const navigate = useNavigate();

  var handleClick = () => {
    console.log("handleClick");
    navigate(`/sensor/${item.uuid}`, { replace: false });
  }

  return (
    <Card variant="outlined" sx={{ height: "100%" }}>
      <CardContent>
        <Typography sx={{fontSize: 14}} color="text.secondary" gutterBottom>
          Name : {item.name ? item.name : item.ident}
        </Typography>
        <Typography sx={{mb: 1.5}} color="text.secondary">
          Place : {item.gw_name ? item.gw_name : item.gw_ident}
        </Typography>
        <Typography sx={{mb: 1.5}} color="text.secondary">
          Battery : {
            item.lasttagdata && (
              item.lasttagdata["BATTERY_PERCENTAGE"]+'%'
            )
          }
        </Typography>
        <Typography sx={{mb: 1.5}} color="text.secondary">
          Last activity : {
            item.lasttagdata && (
              normalizeTim(item.last_activity)
            )
          }
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small" onClick={handleClick}>More</Button>
      </CardActions>
    </Card>
  )
}

function ItemBoxOtpBt5CCGE({item}) {
  const navigate = useNavigate();

  var handleClick = () => {
    console.log("handleClick");
    navigate(`/sensor/${item.uuid}`, { replace: false });
  }

  return (
    <Card variant="outlined">
      <CardContent>
        <Typography sx={{fontSize: 14}} color="text.secondary" gutterBottom>
          Name : {item.name ? item.name : item.ident}
        </Typography>
        <Typography sx={{mb: 1.5}} color="text.secondary">
          Place : {item.gw_name ? item.gw_name : item.gw_ident}
        </Typography>

        <Typography sx={{mb: 1.5}} color="text.secondary">
          {
            item.lasttagdata && (
              item.lasttagdata["TEMPERATURE"]===-128
                ? `Temp : Not available`
                : `Temp : ${item.lasttagdata["TEMPERATURE"].toFixed(2)}°`
              )
          }
        </Typography>
        <Typography sx={{mb: 1.5}} color="text.secondary">
          Battery : {
            item.lasttagdata && (
              item.lasttagdata["BATTERY_VOLTAGE"]+'%'
            )
          }
        </Typography>
        <Typography sx={{mb: 1.5}} color="text.secondary">
          Last activity : {
            item.lasttagdata && (
              normalizeTim(item.last_activity)
            )
          }
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small" onClick={handleClick}>More</Button>
      </CardActions>
    </Card>
  )
}

function ItemBoxOtpBt5CC({item}) {
  const navigate = useNavigate();

  var handleClick = () => {
    console.log("handleClick");
    navigate(`/sensor/${item.uuid}`, { replace: false });
  }

  return (
    <Card variant="outlined" sx={{ height: "100%" }}>
      <CardContent>
        <Typography sx={{fontSize: 14}} color="text.secondary" gutterBottom>
          Name : {item.name ? item.name : item.ident}
        </Typography>
        <Typography sx={{mb: 1.5}} color="text.secondary">
          Place : {item.gw_name ? item.gw_name : item.gw_ident}
        </Typography>
        <Typography sx={{mb: 1.5}} color="text.secondary">
          Last activity : {
            item.lasttagdata && (
              normalizeTim(item.last_activity)
            )
          }
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small" onClick={handleClick}>More</Button>
      </CardActions>
    </Card>
  )
}


function ItemBoxMTL100({item}) {
  const navigate = useNavigate();

  var handleClick = () => {
    console.log("handleClick");
    navigate(`/sensor/${item.uuid}`, { replace: false });
  }

  return (
    <Card variant="outlined" sx={{ height: "100%" }}>
      <CardContent>
        <Typography sx={{fontSize: 14}} color="text.secondary" gutterBottom>
          Name : {item.name ? item.name : item.ident}
        </Typography>
        <Typography sx={{mb: 1.5}} color="text.secondary">
          {
            item.lasttagdata && (
              item.lasttagdata["BUILD_IN_TEMPERATURE"]===-128
                ? `Temp : Not available`
                : `Temp : ${item.lasttagdata["BUILD_IN_TEMPERATURE"]}°`
              )
          }
        </Typography>
        <Typography sx={{mb: 1.5}} color="text.secondary">
          {
            item.lasttagdata && (
              item.lasttagdata["BUILD_IN_HUMIDITY"]===-128
                ? `Humidity : Not available`
                : `Humidity : ${item.lasttagdata["BUILD_IN_HUMIDITY"]}%`
              )
          }
        </Typography>
        <Typography sx={{mb: 1.5}} color="text.secondary">
          {
            item.lasttagdata && (
              item.lasttagdata["BUILD_IN_LIGHT_LUX"]===-128
                ? `Light lux : Not available`
                : `Light lux : ${item.lasttagdata["BUILD_IN_LIGHT_LUX"]}lx`
              )
          }
        </Typography>

        <Typography sx={{mb: 1.5}} color="text.secondary">
          Battery : {
            item.lasttagdata && (
              item.lasttagdata["BATTERY_LEVEL"]+'%'
            )
          }
        </Typography>
        <Typography sx={{mb: 1.5}} color="text.secondary">
          Last activity : {
            item.lasttagdata && (
              normalizeTim(item.last_activity)
            )
          }
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small" onClick={handleClick}>More</Button>
      </CardActions>
    </Card>
  )
}

function ItemBoxUnknown({item}) {
  const navigate = useNavigate();

  var handleClick = () => {
    console.log("handleClick");
    navigate(`/sensor/${item.uuid}`, { replace: false });
  }

  return (
    <Card variant="outlined">
      <CardContent>
        <Typography sx={{fontSize: 14, bgcolor: 'error.main'}} color="text.secondary" gutterBottom>
          Unknown device
        </Typography>
        <Typography sx={{fontSize: 14}} color="text.secondary" gutterBottom>
          Name : {item.name ? item.name : item.ident}
        </Typography>
        <Typography sx={{mb: 1.5}} color="text.secondary">
          Last activity : {
            item.lasttagdata && (
              normalizeTim(item.last_activity)
            )
          }
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small" onClick={handleClick}>More</Button>
      </CardActions>
    </Card>
  )
}

function ItemBox({item}) {

  if (item.device_model === 'TG-BT5-OUT') {
    return (<ItemBoxTgBt5out item={item}/>);
  } else
  if (item.device_model === 'MTL100') {
    return (<ItemBoxMTL100 item={item}/>);
  }
  if (item.device_model === 'OTP-BT5-CC-GE') {
    return (<ItemBoxOtpBt5CCGE item={item}/>);
  }
  if (item.device_model === 'OTP-BT5-CC') {
    return (<ItemBoxOtpBt5CC item={item}/>);
  }
  if (item.device_model === 'TG-BT5-IN') {
    return (<ItemBoxTgBt5in item={item}/>);
  }
  else {
    return (<ItemBoxUnknown item={item}/>);
  }
}

function BasicGrid() {
  var {data, status, isSuccess} = useTagDashboard(5000);
  console.log("data : ", data);

  console.log("status : ", status);
  // if (isFetching) {
  //   return (
  //     <Box sx={{ display: 'flex' }}>
  //       <CircularProgress />
  //     </Box>
  //   )
  //
  //   // return (
  //   //   <Box sx={{ display: 'flex' }}>
  //   //     <Skeleton variant="rectangular" width={210} height={60} />
  //   //   </Box>
  //   // )
  //
  // }

  // if (isSuccess) {
  //   return (
  //     <Box sx={{ flexGrow: 1 }}>
  //       <Grid container spacing={1}>
  //         {
  //           data.map((i) => (
  //             <Paper sx={{height: 300, width: 270}}>
  //             <Grid key={i.uuid} >
  //               <ItemBox item={i}/>
  //             </Grid>
  //           </Paper>
  //           ))
  //         }
  //       </Grid>
  //     </Box>
  //   );
  // }

  if (isSuccess) {
    return (
      <Box sx={{ flexWrap: 'wrap', display: "flex" }}>
          {
            data.map((i) => (
              <Paper sx={{height: 300, width: 270}}>
                <ItemBox item={i}/>
            </Paper>
            ))
          }
      </Box>
    );
  }

}

export default function Dashboard() {
  return (
    <BasicGrid/>
  );
    // return (
    //   <Stack direction="row" spacing={1}>
    //     <BasicGrid/>
    //     <BasicLineChart/>
    //   </Stack>
    // );
  // return (
  //   <Grid container spacing={1}>
  //     <Grid xs={5}>
  //       <Box>
  //         <BasicGrid/>
  //       </Box>
  //     </Grid>
  //     <Grid xs={7}>
  //       <Box>
  //         <BasicLineChart/>
  //       </Box>
  //     </Grid>
  //   </Grid>
  // );
}
