// pretend this is firebase, netlify, or auth0's code.
// you shouldn't have to implement something like this in your own app

const localStorageKey = '__auth_provider_token__'

async function getToken() {
  // if we were a real auth provider, this is where we would make a request
  // to retrieve the user's token. (It's a bit more complicated than that...
  // but you're probably not an auth provider so you don't need to worry about it).
  return window.localStorage.getItem(localStorageKey)
}

function handleUserResponse(d) {
  console.log("handleUserResponse : ", d);
  if (d && d.token) {
    window.localStorage.setItem(localStorageKey, d.token);
  }
  return d;
}

function login({username, password}) {
  return client('login', {username, password}).then(r => {
    if (r.error) {
      return r;
    }
    if (r) {
      return handleUserResponse(r);
    }
    return null;
  });
}

async function logout() {
  return client('logout', {token:window.localStorage.getItem(localStorageKey)}).then(r => {
    if (r.error) {
      return r;
    }
    if (r.result) {
      return handleUserResponse(r.result);
    }
    return null;
  }).finally(() => window.localStorage.removeItem(localStorageKey));

}

// an auth provider wouldn't use your client, they'd have their own
// so that's why we're not just re-using the client
const authURL = process.env.REACT_APP_AUTH_URL;


async function client(endpoint, data) {

  const config = {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {'Content-Type': 'application/json'},
  }

  console.log('auth provider fetch : ', `${authURL}/${endpoint}`, config)
  return window.fetch(`${authURL}/${endpoint}`, config).then(async response => {
    const data = await response.json()
    if (response.ok) {
      return data
    } else {
      return Promise.reject(data)
    }
  })
}

export {getToken, login, logout, localStorageKey}
