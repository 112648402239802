import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'

import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { AuthProvider } from './auth-context.js'

const queryClient = new QueryClient();

function AppProviders({ children }) {
  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <AuthProvider>
          {children}
        </AuthProvider>
      </Router>
    </QueryClientProvider>
  )
}

export { AppProviders }
