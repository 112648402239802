import * as React from 'react';
import { useParams } from 'react-router-dom';
import { LineChart } from '@mui/x-charts/LineChart';
//import Typography from '@mui/material/Typography';
//import { ChartsReferenceLine } from '@mui/x-charts/ChartsReferenceLine';
//import { PiecewiseColorLegend } from '@mui/x-charts/ChartsLegend';


import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import {useSensorData, useSensorProperties} from '../utils/sensor';


function Chart({uuid}) {
  var {data, isFetching} = useSensorData(uuid);
  //console.log("Chart data : ", data);

  if (isFetching) {
    return (
      <Box sx={{ display: 'flex' }}>
        <CircularProgress />
      </Box>
    )
  }

  var plotRes = [];
  var x = 0;
  for (var i = 0; i < data.length; i++) {
    if (data[i].tag_data) {
      x += 1;
      plotRes.push({
        temp : data[i].tag_data.TEMPERATURE,
        //data_timestamp : new Date(data[i].formated_tim)
        //data_timestamp : data[i].formated_tim,
        date : new Date(data[i].formated_tim),
        inc : x
      });
    }
      //data[i]["temp"] = data[i].tag_data.TEMPERATURE;
  }
  console.log("plotRes : ", plotRes);

  return (
  <LineChart
    dataset={plotRes}
    xAxis={[{
      dataKey: 'date',
      valueFormatter: (date) => {
        var d = new Date(date);
        var datestring = d.getHours()+":"+d.getMinutes();
        return datestring
      }
    }]}
    series={[{
      dataKey: 'temp',
      showMark: false,
    }]}
    yAxis={[
      {
        min: -30,
        max: 50,
        disableLine: true,
        disableTicks: true,
        valueFormatter: (value, context) => {
//          console.log("yAxis value : ", value, " context : ", context);
          return `${value}°`
        },
        colorMap: {
          type: 'continuous',
          color: (d) => {
            if (d > 0) {
              return 'red';
            } else {
              return 'blue';
            }
          }
        },
      },
    ]}
    height={300}
    margin={{ left: 30, right: 30, top: 30, bottom: 30 }}
    grid={{ vertical: true, horizontal: true }}
  />
);
}

//<SimpleChart data={tagData} key="build_in_temperature"/>
function SimpleChart({data, dataKey, min, max, unit}) {

  console.log("dataKey : ",dataKey);
  console.log("data : ",data);

// return(
//   <div>a</div>
// );

  return (
  <LineChart
    dataset={data}
    xAxis={[{
      dataKey: 'date',
      valueFormatter: (date) => {
        var d = new Date(date);
        var datestring = d.getHours()+":"+d.getMinutes();
        return datestring
      }
    }]}
    series={[{
      dataKey: dataKey,
      showMark: false,
    }]}
    yAxis={[
      {
        min: min,
        max: max,
        disableLine: true,
        disableTicks: true,
        valueFormatter: (value, context) => {
//          console.log("yAxis value : ", value, " context : ", context);
          return `${value}${unit}`
        },
        colorMap: {
          type: 'continuous',
          color: (d) => {
            if (d > 0) {
              return 'red';
            } else {
              return 'blue';
            }
          }
        },
      },
    ]}
    height={300}
    margin={{ left: 40, right: 30, top: 30, bottom: 30 }}
    grid={{ vertical: true, horizontal: true }}
  />
);
}

function ChartMtl100({uuid, attributes}) {
  var {data, isFetching} = useSensorData(uuid);
  console.log("ChartMtl100 Chart data : ", data);

  if (isFetching) {
    return (
      <Box sx={{ display: 'flex' }}>
        <CircularProgress />
      </Box>
    )
  }

  var tagData = [];
  for (let i = 0; i < data.length; i++) {
    var l = data[i]["tag_data"]["SENSOR"];

    var d = new Date(l["sample_rtc_time"]);
    var userTimezoneOffset = d.getTimezoneOffset() * 60000;
    l["date"] = new Date(d.getTime() + userTimezoneOffset);

    //l["date"] = new Date(l.sample_rtc_time);
    //l["date"] = Date.parse(l.sample_rtc_time);
    l["build_in_light_lux"] = parseFloat(l["build_in_light_lux"]);
    l["build_in_temperature"] = parseFloat(l["build_in_temperature"]);
    tagData.push(l);
  }
  console.log("tagData : ", tagData);

  return (
    <Box>
      <SimpleChart data={tagData} dataKey="build_in_temperature"
      min={-30} max={70} unit="°"/>
      <SimpleChart data={tagData} dataKey="build_in_humidity"
        min={0} max={100} unit="%"/>
      <SimpleChart data={tagData} dataKey="build_in_light_lux"
        min={0} max={450} unit="lx"/>
      </Box>
  )


}

export default function SensorScreen() {
  const { uuid } = useParams();
  console.log("uuid : ", uuid);

  var {data, isFetching} = useSensorProperties(uuid);

  if (isFetching) {
    return (
      <Box sx={{ display: 'flex' }}>
        <CircularProgress />
      </Box>
    )
  }

  console.log("data : ", data);

  if (data.device_model	=== 'MTL100') {
    return (
      <ChartMtl100 uuid={uuid} attributes={data}/>
    )
  } else {
    return (
      <Chart uuid={uuid} attributes={data}/>
    )
  }
}
